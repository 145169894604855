













import { Component, Prop, Vue } from 'vue-property-decorator'
import IconBellFilled from '@/components/UI/icons/IconBellFilled.vue'
import IconBellSlash from '@/components/UI/icons/IconBellSlash.vue'

@Component({ name: 'ChatMuteIcon' })
export default class ChatMuteIcon extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  }) readonly value!: boolean

  @Prop({
    type: String,
    default: '14px',
  }) readonly size!: string

  iconBellSlash: any
  iconBellFilled: any

  isHovered = false

  get icon () {
    return this.isHovered
      ? (this.value ? this.iconBellSlash : this.iconBellFilled)
      : (this.value ? this.iconBellFilled : this.iconBellSlash)
  }

  get tooltipCaption (): string {
    return this.value
      ? this.$t('chattape.disableNtf').toString()
      : this.$t('chattape.enableNtf').toString()
  }

  created () {
    this.iconBellFilled = IconBellFilled
    this.iconBellSlash = IconBellSlash
  }
}
